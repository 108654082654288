import React from 'react'
import "./schedule.css"
import Days from '../days/days'


const Schedule = () => {
  return (
    <div className="schedulebody">
        <p className="scheduleheading">
            SCHEDULE
        </p>
        <h3 className="day">Day 1</h3>
        <div className="sdays">
        
        <Days speaker="Moosa Meher" time="11:00-11:45" topic="From idea to innovation"/>
        <Days speaker="Neeraj Manoharan" time="12:15-1:00" topic="Crafting tomorrow's products"/>
        <Days speaker="Muhammed Iqbal P B" time="3:00-3:45" topic="Demystifying DevOps"/>
        <Days speaker="Aldrin Jenson" time="4:00-5:00" topic="Unveiling the world of FOSS"/>
            

        </div>
        <h3 className="day">Day 2</h3>
        <div className="sdays">
        <Days speaker="Arsh Goyal" time="10:00-10:50" topic="Pioneering Your Tech Career  "/>
        <Days speaker="Kiran S Raj" time="10:55-11:40" topic="Embark on Your AI Odyssey"/>
        <Days speaker="Abirami Sukumaran" time="12:00-1:00" topic="Exploring Google Cloud, Cope Vipasana, and Beyond "/>
        <Days speaker="Manoj Chandran" time="3:30-4:30" topic="Mastering DevOps"/>
        </div>
    </div>
  )
}

export default Schedule