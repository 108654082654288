import React from 'react'
import styles from "./faq.module.css"
import Faqcard from '../Faqcard/faqcard';

const Faq = () => {
  return (
    <div className={styles.faqbody}>
        <h2 className={styles.faqtitle}>FAQ</h2>
        <div className={styles.faqcontainer}>
          
        <Faqcard 
          question="What are the dates and location of MEC.Conf?"
          answer="MEC.Conf is scheduled to take place on 7th and 8th October 2023 at Model Engineering College, Thrikkakara, Kochi."
        />
        <Faqcard
          question=" Who can attend this tech conference?"
          answer="MEC.Conf is open to anyone, from those who are curious about new developments in tech to those looking to learn about emerging fields and to network with or meet new people. It's an inclusive tech conference for a wide range of interests and backgrounds."
        />
        <Faqcard
          question="What can I expect to gain from attending MEC.Conf ?"
          answer=" Attending MEC.Conf offers a unique opportunity to gain knowledge about the latest tech trends, network with industry professionals, and draw inspiration from innovative ideas. You can also enhance your technical know how through the talk sessions, explore career prospects, and become part of a vibrant tech community. With engaging activities and social events, it's a conference that combines learning with fun, making it a valuable experience for tech enthusiasts of all levels."
        />
        <Faqcard
          question="How can I network with other participants and speakers during the conference ?"
          answer=" Networking at MEC.Conf is made easy with dedicated networking breaks during the two-day event. You can connect with fellow participants and speakers during these breaks, have one-on-one or group meetings and exchange contact information with ease. This ensures ample opportunities to build valuable connections and make the most of your conference experience."
        />
        <Faqcard
          question="What is the theme or focus of the conference?"
          answer="MEC.Conf is uniquely tailored for beginner tech enthusiasts looking to explore the realms of AI, DevOps, FOSS (Free and Open Source Software), Google Cloud technology, and the broader domains of software and hardware development. The conference's overarching focus is on providing a comprehensive introduction to these tech fields, offering attendees a foundation to dive into these exciting areas and kickstart their tech journeys."
          />
        <Faqcard
          question="Are there any special discounts or group rates available for registration?"
          answer="Yes, we have special discounts and group rates available for registration. If a group of 10 or more individuals register together, each member of the group will receive a 10 percent discount on their tickets. Additionally, there are exclusive discounts for IEEE (Institute of Electrical and Electronics Engineers) members and IEEE Computer Society (IEEE CS) members. Please contact one of our team members to avail this discount."
        />
      </div>
    </div>
  )
}

export default Faq