import React,{ useState } from 'react'
import {DefaultPlayer as VideoP}from 'react-html5video'
import Video from "../../assets/final.mp4"
import Thumbnail from "../../assets/Thumbnail.jpg"
import 'react-html5video/dist/styles.css'
import "./video.css"

const Videos = () => {

  
  return (
    <div className='videobody'>
        <div className="videocontainer">
        <VideoP
        poster={Thumbnail}
        width={300}
        height={300}
        >
            <source src={Video}type="video/mp4" />
        </VideoP>
        </div>
    </div>

  )
}

export default Videos
