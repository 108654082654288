import React from 'react'
import Meme from "../../assets/Meme7.jpeg"
import "./qr.css"
const qr7 = () => {
  return (
    <div className='qrbody'>
      <img src={Meme} alt="" className="qrimg" />
    </div>
  )
}

export default qr7
