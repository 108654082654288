import React from 'react'
import Meme from "../../assets/Meme2.jpeg"
import "./qr.css"
const qr2 = () => {
  return (
    <div className='qrbody'>
    <img src={Meme} alt="" className="qrimg" />
  </div>
  )
}

export default qr2
