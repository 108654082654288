import React from 'react'
import  './reg.css'
import ticket from '../../assets/noto-v1_ticket.svg'


const Reg = () => {
  return (
    <div className="regbody">
        <h2 className="regtitle">REGISTRATION</h2>
        <div className="reg1">
          <div className="reg2">
          <img className="regimg" src={ticket} alt="registration"/>
          <p className='forwho'>FOR IEEE CS MEMBERS</p>
          <p className='pricing'>₹ 650</p>
          </div>
          <div className="reg2">
          <img className="regimg" src={ticket} alt="registration"/>
          <p className='forwho'>FOR IEEE MEMBERS</p>
          <p className='pricing'>₹ 700</p>
          </div>
          <div className="reg2">
          <img className="regimg" src={ticket} alt="registration"/>
          <p className='forwho'>FOR NON-IEEE MEMBERS</p>
          <p className='pricing'>₹ 800</p>
          </div>
        
        </div>
      
        <p className="regtext">Don't miss out on the chance to connect with industry leaders, gain invaluable knowledge, and shape the future of technology. Reserve your spot today and be part of the MEC.Conf experience – it's your time to register and discover endless opportunities!</p>
        <a target='_blank' href="https://forms.gle/wsXhD3Fo3smgTCyJA"><button className="regbtn">REGISTER HERE</button></a>
      
    </div>
  )
}

export default Reg