import React from 'react'
import Meme from "../../assets/Meme5.jpeg"
import "./qr.css"
const qr5 = () => {
  return (
    <div className='qrbody'>
    <img src={Meme} alt="" className="qrimg" />
  </div>
  )
}

export default qr5
