import React from 'react'
import './speakers.css'
import Speakerscard from '../speakerscard/speakerscard'
import Arsh from "../../assets/arsh.png"
import Iqbal from "../../assets/iqbalpng.png"
import Mehar from "../../assets/mehar.png"
import Neeraj from "../../assets/Neeraj.png"
import Kiran from "../../assets/Kiran.png"
import Abhi from "../../assets/abhir.png"
import Aldrin from "../../assets/aldrin.png"
import Manoj from "../../assets/manoj.png"
const speakers = () => {
  return (
    <>
      <div className="speakersbody">
        <h2 className="speakerstitle">SPEAKERS</h2>
        <div className="speakerscontainer">
          <Speakerscard 
          img={Arsh}
          name="Arsh Goyal"
          desc="Meet Arsh Goyal, a tech conference speaker bridging educational gaps with 500k+ followers. He mentors thousands for tech placements and educates at Unacademy and CodeChef, with 100+ college sessions. Don't miss his insights!  "
          
        
          
          />
          <Speakerscard img={Abhi} name="Abhirami Sukumaran" desc="Abirami Sukumaran, a Google developer advocate, specializes in cloud databases. She's the visionary founder of Code Vipassana Epoch, empowering developers with 17 years of industry experience. Don't miss her insights!"/>
          <Speakerscard img={Neeraj} name="Neeraj Manoharan" desc="Meet Neeraj Manoharan, COO of Riafy, a trailblazer in AI app development. His visionary leadership propels AI innovation. Join us at MEC.Conf 2023 for insights into shaping the future of AI."/>
          <Speakerscard  img={Mehar} name='Moosa Mehar MP' desc="Meet Moosa Mehar MP, co-founder of Tinkerhub Foundation. A visionary bridging India's skill gap, he champions open-source culture, innovation, and collaboration. Join us to discover his transformative impact in tech education."/>
          <Speakerscard img={Aldrin} name="Aldrin Jenson" desc="Meet Aldrin Jenson, a Generalist Programmer and IndiaFOSS volunteer. He's at the forefront of building high-performance open-source analytics at Parseable and will discuss the transformative power of FOSS at MEC.Conf 2023."/>
          <Speakerscard img={Iqbal} name="Muhammed Iqbal P B" desc="Don't miss Muhammed Iqbal P B, an expert in DevOps, cloud, security, and WordPress. With roles at CodeAce, AWS community, and Cloud Native Computing Foundation Kochi, he offers valuable insights at our tech conference."/>
          <Speakerscard img={Kiran} name="Kiran S Raj" desc="Discover AI/IA insights from a GDG Cloud Kochi lead and Buildnext research SDE, a Kaggle expert. Join us at the conference for valuable perspectives on the ever-evolving world of technology."/>
          <Speakerscard img={Manoj} name='Manoj Chandran' desc="Introducing Manoj Chandran, a DevOps Engineer at UST and our sixth speaker at MEC.Conf 2023. With a decade of experience, he's committed to pushing the boundaries of DevOps. Join us for his insights!"/>
         
        </div>

      </div>
    </>
  )
}

export default speakers
