import React from 'react'
import "./navbar.css"
import Navlogo from "../../assets/navlogo.png"
import { Link } from 'react-scroll'

const Navbar = () => {
  return (
    <div>
      {/* <div className="navbody">
        <div className="navcontainer">
       <img className="navlogo"src={Navlogo} alt="" />
        <div className="navlinks">
          <a className="linktext" href="">HOME</a>
          <a className="linktext" href="">ABOUT</a>
          <a className="linktext" href="">SPEAKERS</a>
          <a className="linktext" href="">REGISTRATION</a>
          <a className="linktext" href="">SPONSERS</a>
          <a className="linktext" href="">FAQ</a>
          <a className="linktext" href="">CONTACT</a>
        </div>
        <button className='navbtn'>Get ticket</button>
      </div>
      </div> */}
       <section class="top-nav">
    <div>
    <img className="navlogo"src={Navlogo} alt="" />
    </div>
    <input id="menu-toggle" type="checkbox" />
    <label class='menu-button-container' for="menu-toggle">
    <div class='menu-button'></div>
  </label>
    <ul class="menu">
      <li><Link
      to='home'
      spy={true} smooth={true} offset={0} duration={500}
      >
      
      
      
      
      HOME</Link></li>



      <li><Link
      to="about"
      spy={true} smooth={true} offset={0} duration={500}

      
      >ABOUT</Link></li>
      {/* <li>SPEAKERS</li> */}


      <li><Link
      to="events"
      spy={true} smooth={true} offset={-50} duration={500}

      
      >EVENTS</Link></li>



      <li><Link
      to="register"
      spy={true} smooth={true} offset={-10} duration={500}
      >REGISTER</Link></li>
      <li><Link
      to="faq"
      spy={true} smooth={true} offset={-20} duration={500}

      
      >FAQ</Link></li>
      <li><Link
      to="contact"
      spy={true} smooth={true} offset={0} duration={500}

      
      >CONTACT</Link></li>

      {/* <li>EVENTS</li>
      <li>REGISTER</li>
      <li>FAQ</li>
      <li>CONTACT</li> */}
    </ul>
    <a target='_blank' href='https://forms.gle/wsXhD3Fo3smgTCyJA'> <button className='navbtn'>Get ticket</button> </a>
  </section>

    </div>
  )
}

export default Navbar
