import React from 'react'
import Meme from "../../assets/meme1.jpeg"
import "./qr.css"
const qr1 = () => {
  return (
    <div className='qrbody'>
      <img src={Meme} alt="" className="qrimg" />
    </div>
  )
}

export default qr1
