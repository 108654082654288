import React from 'react'
import Reg from "../../components/reg/reg.jsx";
import Faq from "../../components/Faq/Faq.jsx";
import Events from "../../components/events/events.jsx";
import Speakers from '../../components/speakers/speakers';
import Schedule from '../../components/schedule/schedule';
import About from '../../components/about/about';
import Video from "../../components/video/video.jsx"
import Navbar from "../../components/navbar/navbar.jsx"
import Hero from "../../components/hero/hero.jsx"
import Contact from "../../components/contact/contact.jsx"
import './Home.css'
const Home = () => {
  return (
    <div className="App">
      <div className="bg"></div>
      <Navbar />
      <div id="home"><Hero /></div>
      <Video />
      <div id="about"><About /></div>
      <Speakers />
      <div id="events"className="subbg1"><Events /></div>
      <div id='register'className='subbg1'><Reg /></div>
      
      <div id='faq'><Faq /></div>
      <Schedule />
      <div id='contact'><Contact /></div>
      
      
     
      
      
    </div>
  )
}

export default Home
