import React from 'react'
import "./about.css"

const About = () => {
  return (
    <div className='aboutcontainer'>
        <div className="abtheader">
            <div className="abtheading">
                ABOUT <br />MEC.CONF
            </div>
            <div className="abtdesc"><br/> Experience a personalized journey, where coding sparks and limitless ideas come to life
</div>
        </div>
        <div className="abtpara">
        MEC.Conf 2023 is more than just a conference; it's a journey where technology meets creativity, and innovation knows no bounds. It's all about building up that cool developer community right among the college students in Kochi. We're not just stopping at that – our big goal is to light up that spark for coding and all things development.
<br /><br />And guess what? We're not about big crowds and lost faces. Nope, we're aiming to give you that one-on-one attention you deserve. We're rolling out the red carpet of value for each and every participant. So tech devs out there, get ready to immerse yourself in a world of limitless ideas and groundbreaking discoveries.
        </div>
    </div>
  )
}

export default About