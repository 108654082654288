import React from 'react'
import "./days.css"

const Days = (props) => {
  return (
    <div className="days">
        <div className="time">
            <p>{props.time}</p>
        </div>
        <div className="who">
            <p>{props.speaker}</p>
        </div>
        <div className="what"><p>{props.topic}</p></div>
    </div>
  )
}

export default Days