import React from 'react'
import './events.css'
import Eventscard from '../eventscard/eventscard'
const events = () => {
  return (
    <>
      <div className="eventsbody">
        <h2 className="eventstitle">EVENTS</h2>
        <div className="eventscontainer">
            <Eventscard
                id = {1}
                linktitle ="MEC HACK Website"
                title="MEC.HACK"
                link="https://www.mec-hack.site/"
                text="24 hour Fintech hackathon"
                description="Get ready for an incredibly thrilling 24-hour hackathon focused on igniting your creativity and innovation! So, here's the deal: the fun kicks off with teams pitching their cool ideas and projects in the first round after which the top 15 teams would be cherry-picked for this amazing adventure. These teams won't just be dreaming – they'll be diving in headfirst to bring those ideas to life. Think crafting prototypes, creating basic websites or apps – the whole shebang.
                But here's where things get even cooler – these prototypes won't stop at being just that. Nope, they're destined to grow into fully-fledged open-source projects! Yep, that means turning your brainwaves into something awesome that the whole tech community can get behind. So, charge up those laptops and get ready for a coding marathon full of teamwork, innovation, and limitless possibilities."
            />
            <Eventscard
                id={2}
                linktitle="Code Clash Registration Link"
                title="CODE CLASH 23&#39;"
                link="https://bit.ly/CodeClash23"
                text="May the fastest coder win"
                description="Gear up for the ultimate coding showdown at the Online Coding Competition! Get ready to flex your coding muscles in the initial online rounds, leading up to the electrifying final face-off live on stage on the second day of MEC.Conf.
                It's all about teamwork – teams of 2 will enter the ring, battling it out for glory. And when we say final round, we mean the crème de la crème – only the top 4 teams will lock horns in a battle of wits, strategy, and coding brilliance.
                Don't just be a spectator – be a contender in this coding extravaganza!
                "
            />
            <Eventscard
                title="TECH SHOWCASE"
                text="A showcase where tech and talent unite "
                description="Welcome to the arena of tech where innovation takes the spotlight! Picture this: a grand platform that brings together the most fascinating software and hardware marvels, all crafted by startups right here in Kerala. Yes, we're talking about the hottest tech creations that are to revolutionize the face of the technoworld.
                It's your chance to immerse yourself in a world of innovation, get up close with the genius minds behind these products and witness firsthand the incredible strides our local startups are making in the tech universe. Join us for an unforgettable journey that celebrates the power of ideas and the limitless possibilities of technology!
                "
                
            />
            <Eventscard
                title="MENTORSHIP"
                text=" Building connections that  skyrocket your personal growth"
                description="Ready to supercharge your learning? Get pumped for our mentorship program at MEC.Conf! We're bringing in the pros of the field to guide you through the tech maze.
                Picture this: your mentors don't just give you tips; they practically build a personalized roadmap for your career journey, whether you're all about coding or love tinkering with hardware. They're your compass for the latest trends, the go-to folks for mastering the must-have skills, and they'll even throw cool project ideas your way. From nailing down achievable goals to hooking you up with top-notch resources and awesome networking chances, they've got your back.
                
                
                So, think of it as having your own tech guide to take you on an amazing journey. Get ready to soak up knowledge, make some epic connections, and feel inspired by the best in the biz!
                
                "
            />
            <Eventscard
                title="SPEAKER SESSIONS"
                text="Sessions to unleash innovation and enlighten your mind"
                description="Imagine a conference without speakers or panel discussions – it's like missing out on the essence of the event! But guess what? We're all about exceeding expectations. Brace yourself for an incredible journey with captivating speaker sessions and engaging panel discussions where tech luminaries, industry veterans and thought leaders converge to share their insights.


                From riveting solo talks that unravel coding mysteries to dynamic panel discussions that spark lively debates, these sessions are your ticket to gain fresh perspectives, learn from the best, and connect with fellow tech enthusiasts. Don't miss out on this unparalleled opportunity to expand your horizons and soak up wisdom from the brightest minds of the tech realm!
                "
            />
            
        </div>
      </div>
    </>
  )
}

export default events
