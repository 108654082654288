
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Home from "./Pages/Home/Home.jsx"
import Qr1 from "./Pages/QRs/qr1.jsx"
import Qr2 from "./Pages/QRs/qr2.jsx"
import Qr3 from "./Pages/QRs/qr3.jsx"
import Qr4 from "./Pages/QRs/qr4.jsx"
import Qr5 from "./Pages/QRs/qr5.jsx"
import Qr6 from "./Pages/QRs/qr6.jsx"
import Qr7 from "./Pages/QRs/qr7.jsx"

function App() {
  return (
    <div className="App">
      {/* <div className="bg"></div>
      <Navbar />
      <div id="home"><Hero /></div>
      <Video />
      <div id="about"><About /></div>
      <Speakers />
      <div id="events"className="subbg1"><Events /></div>
      <div id='register'className='subbg1'><Reg /></div>
      
      <div id='faq'><Faq /></div>
      <Schedule />
      <div id='contact'><Contact /></div>
      
      
     
      
       */}
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/743182" element={<Qr1 />} />
          <Route path="/286012" element={<Qr2 />} />
          <Route path="/673753" element={<Qr3 />} />
          <Route path="/558167" element={<Qr4 />} />
          <Route path="/918417" element={<Qr5 />} />
          <Route path="/301566" element={<Qr6 />} />
          <Route path="/421774" element={<Qr7 />} />
        </Routes>  
    </div>
  );
}
export default App;
