
import React from 'react'
import ContactCard from "../contactcard/contactcard"
import styles from './contact.module.css'
import Theertha from '../../assets/theertha.jpeg'
// import Karthik from "../../assets/karthik.png";
import Stephen from "../../assets/stephen.jpg";
// import Jithin from "../../assets/jithin.JPG";
import Alan from "../../assets/alan.jpeg";
import Roshin from "../../assets/roshin jimmy.jpg"
const contact = () => {
  return (
    <div className={styles.contactbody}>
      <h2 className={styles.contacttitle}>CONTACT</h2>
      <div className={styles.contactholder}>
        <ContactCard
          name="Stephen Saji Mathew"
          email="stephensajimathew@ieee.org"
          mobile="+917012656996"
          designation=" Chairperson of IEEE MEC CS SBS"
          link="https://www.linkedin.com/in/stephen-mathew-420702206"
          img={Stephen}
          id="1"
        />
               <ContactCard
          name="Alan Biju"
          email="alanbiju@ieee.org"
          mobile="+917907025154"
          designation="Secretary of IEEE MEC CS SBS"
          link="https://www.linkedin.com/in/alan--biju"
          img={Alan}
          id="3"
        />
        
      </div>
      <div className={styles.contactholder}>

        <ContactCard
          name="Theertha Avinash"
          email="theerthaavnsh@gmail.com"
          mobile="+919400882017"
          designation="Lead Organizer - MEC.CONF 2023"
          link="https://www.linkedin.com/in/theertha-avinash"
          img={Theertha}
          id="4"
        />
         <ContactCard
          name="Roshin Jimmy"
          email="roshinjimmy@ieee.org"
          mobile="+917593035625"
          designation="Activity Coordinator, IEEE CS MEC SBC"
          link="https://www.linkedin.com/in/roshinjimmy"
          img={Roshin}
          id="2"
        />
        
      </div>
    </div>
  );
}

export default contact