import {useState} from 'react'
import React from 'react'
import './eventscard.css'

const Eventscard = (props) => {
  var id=props.id;
  const [modal,setModal]=useState(false)
  const toggleModal = () => {
    setModal(!modal);
  }

  
  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className={"overlay"}></div>
          <div className={"modalbox"}>
            <div className={"modalcontent"}>
                <h2 className={"modaltitle"}>{props.title}</h2>
                <p className={"modaltext"}>{props.description}</p>
                {(id === 1 || id === 2) && (
                  <a className="your-button-class" href={props.link} >{props.linktitle}</a>
                )}
                <button className={"closemodal"} onClick={toggleModal}>
                {/* <AiOutlineClose size={24} /> */}X
              </button>
            </div>
            </div>
        
    </div>
      
  )
  }
        
        <div className="eventscardbody">
          <div onClick={toggleModal}className="cardcontent">
          <h2 className="eventcardtitle">{props.title}</h2>
            <p className="eventcardtext">{props.text}</p>
          </div>
           
        </div>
    </>
  )
}

export default Eventscard
