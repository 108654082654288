
import React from 'react'
import "./speakerscard.css"


const Speakerscard = (props) => {
  return (
    <div className='container'>
      <div className="card">
        <div className="content">
            <h2 className="speakername">
                {props.name}
            </h2>
            <p className="speakerabout">{props.desc}</p>
        </div>
        <img src={props.img} alt="" className="speakerimg" />
      </div>
    </div>
  )
}

export default Speakerscard
