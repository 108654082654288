import React from 'react'
import Bmw from "../../assets/bmw.png"
import "./hero.css"
import ieeecs from "../../assets/ieeecs.png"
import ieeemec from "../../assets/ieeemec.png"
import foss from "../../assets/FOSS.png"
import tle from "../../assets/tle.png"
import dsc from "../../assets/dsc.png"
import Herologo from "../../assets/herologo.png"
import Location from "../../assets/location.svg"
import Date from "../../assets/date.svg"

const hero = () => {
  return (
    <>
      <div className="herobody">
        <div className="herocontainer">
        <img className="heroimg" src={Herologo} alt="mec.conf logo" />
        <div className="datelocation">
          <div className="date">
            <img className='dateimg' src={Date} alt="" />
            <p className='datetext'>7th-8th October</p>
          </div>
          <div className="location">
          <img className='locationimg' src={Location} alt="" />
          <p className='locationtext'> Govt. Model Engineering<br/> College,Thrikkakara</p>
          </div>
        </div>
        <a target='_blank' href='https://forms.gle/wsXhD3Fo3smgTCyJA'><button className='herobtn'>Get your tickets</button></a> 
        </div>
        <div className="herocontainer2">
        <div className="herosponsers">
            <img className="bmw"src={ieeecs} alt="" />
            <img className="media"src={dsc} alt="" />
            <img className="amazon" src={ieeemec} alt="" />
            <img className="qnb" src={foss} alt="" />
            <img className="qnb"src={tle} alt="" />
            
            </div>
        </div>
      </div>
    </>
  )
}

export default hero